import React from 'react'

import Hero from '../../components/Hero'
import Layout from '../../components/layout'

import Helmet from 'react-helmet'
import { Img } from '@tueri/react'
// import Button from '../../components/Button'
import { Link } from 'gatsby'

import './docs.css'

const meta = {
    title: 'Documentation',
    image: '274877906965',
    path: '/docs/',
    description: 'Documentation for integrating Tueri.io into ReactJS and Vanilla Javascript',
    keywords: 'react image optimization, image optimization image lazy loading'
  }

  const Docs = () => (
      <Layout blog>
          <Helmet
            html={[
              { lang: 'en', prefix: 'og: http://ogp.me/ns#' }
            ]}
            meta={[
              { property: 'og:title', content: 'Tueri - ' + meta.title },
              { property: 'twitter:title', content: 'Tueri - ' + meta.title },
              { property: 'og:type', content: 'website' },
              { property: 'og:image', content: 'https://cdn.tueri.io/' + meta.image + '/' + encodeURIComponent(meta.title) + '.jpg?w=1200&height=1200' },
              { property: 'twitter:image:src', content: 'https://cdn.tueri.io/' + meta.image + '/' + encodeURIComponent(meta.title) + '.jpg?w=1200&height=1200' },
              { property: 'og:url', content: 'https://tueri.io' + meta.path },
              { property: 'og:description', content: meta.description },
              { property: 'twitter:description', content: meta.description },
              { name: 'description', content: meta.description },
              { name: 'keywords', content: meta.keywords },
              { name: 'author', content: 'Dane Stevens' },
              { property: 'twitter:card', content: 'summary_large_image' }
            ]}
          >
            <title>Tueri - { meta.title }</title>
          </Helmet>
          <Hero
            large
            title={`Get up and running fast`}
            subTitle={`with docs to integrate Tueri.io into your applications`}
        />

        <div className='docs__container'>
            
            <div className='docs__integrationContainer'>
                <div className='docs__integrationImage' style={{ width: '200px', marginBottom: '1rem' }}>
                    <Img src='https://cdn.tueri.io/274877907168/1200px-React-icon.svg.png' alt='Tueri.io for ReactJS' />
                </div>
                <Link className='button' to={`/docs/reactjs-integration`}>Integrate with React</Link>
            </div>

            <div className='docs__integrationContainer'>
                <div className='docs__integrationImage'  style={{ width: '120px', marginTop: '1rem', marginBottom: '2.1rem' }}>
                    <Img src='https://cdn.tueri.io/274877907173/1200px-Unofficial_JavaScript_logo_2.svg.png' alt='Tueri.io for Vanilla Javascript' />
                </div>
                <Link className='button' to={`/docs/javascript-integration`}>Integrate with Javascript</Link>
            </div>

        </div>

      </Layout>
  )

  export default Docs